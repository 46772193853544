import * as React from "react";
import { closeDialog } from "bernie-core";
import { useLocation, useHistory } from "react-router-dom";
import { observer, inject } from "mobx-react";
import { UitkLayoutFlexItem, UitkLayoutFlex } from "uitk-react-layout-flex";
import { UitkSpacing } from "uitk-react-spacing";
import { UitkCard, UitkCardContentSection, UitkCardLink } from "uitk-react-cards";
import { WindowWithChatbot, ClientChatbotStore } from "src/stores/chatbotStore/ClientChatbotStore";
import { UitkIcon } from "uitk-react-icons";
import { UitkText } from "uitk-react-text";
import { sendAnalytics } from "./NotificationQueryUtils";
import { Link, Action } from "../types";

export interface DialogActionProps {
  chatbot?: ClientChatbotStore;
  link?: Link;
  analyticsCallback?: (rfrr: string, linkName: string | null) => void;
}

export const DialogAction: React.FunctionComponent<DialogActionProps> = inject("chatbot")(
  observer((props: DialogActionProps) => {
    const { link, analyticsCallback, chatbot } = props;
    const chatBotId = "openChatbot";
    const [initedChatClient, setInitedChatClient] = React.useState<boolean>(false);
    const history = useHistory();
    const location = useLocation();

    React.useEffect(() => {
      (async () => {
        setInitedChatClient(
          chatbot !== undefined && (await chatbot.isVirtualAgentInitialized(window as WindowWithChatbot))
        );
      })();
    }, []);
    const useChatbot = link?.id === chatBotId && initedChatClient;
    const actionHref = useChatbot ? "#" : link?.uri?.value;
    /* istanbul ignore next */
    const onChatbotClick = (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();
      if (analyticsCallback) {
        sendAnalytics(link?.actions as Action[], analyticsCallback);
      }
      closeDialog({ history, location });
      if (useChatbot && chatbot) {
        chatbot.openChatWidget();
      }
    };

    /* istanbul ignore next */
    const onLinkClick = (e: React.MouseEvent<HTMLElement>) => {
      if (analyticsCallback) {
        sendAnalytics(link?.actions as Action[], analyticsCallback);
      }
    };

    return (
      <UitkLayoutFlexItem minWidth="full_width">
        <UitkCard border>
          <UitkSpacing padding="three">
            <UitkCardContentSection>
              <UitkLayoutFlex justifyContent="space-between" alignItems="center" className="cardContentContainer">
                <UitkLayoutFlexItem>
                  <div>
                    <UitkText weight="medium">{link?.text}</UitkText>
                  </div>
                </UitkLayoutFlexItem>
                <UitkLayoutFlexItem>
                  <UitkIcon name="chevron_right" />
                </UitkLayoutFlexItem>
              </UitkLayoutFlex>
            </UitkCardContentSection>
          </UitkSpacing>
          <UitkCardLink>
            {useChatbot ? (
              <a onClick={onChatbotClick} href={actionHref}>
                {link?.text}
              </a>
            ) : (
              <a onClick={onLinkClick} href={actionHref}>
                {link?.text}
              </a>
            )}
          </UitkCardLink>
        </UitkCard>
      </UitkLayoutFlexItem>
    );
  })
);
