import * as React from "react";
import { getBexApiContext, Context } from "bernie-context";
import { AnalyticsStore, PageStore } from "bernie-plugin-mobx";
import { inject } from "mobx-react";
import { CustomerNotificationWithDialog } from "./NotificationsWithDialog/CustomerNotificationWithDialog";
import { getIdentity } from "./NotificationsWithDialog/NotificationQueryUtils";
import { NotificationLocationOnPage } from "__generated__/typedefs";
import { UitkSpacing } from "uitk-react-spacing";

interface CustomerNotificationsWithDialogProps {
  context?: Context;
  analytics?: AnalyticsStore;
  location: NotificationLocationOnPage;
  page?: PageStore;
  className?: string;
}

export const CustomerNotificationsWithDialogWrapper: React.FC<CustomerNotificationsWithDialogProps> = inject(
  "analytics",
  "context",
  "page"
)((props: CustomerNotificationsWithDialogProps) => {
  const { analytics, context, location, page, className } = props;

  if (!page || !page.pageId || !context) {
    return null;
  }

  const identity = getIdentity(page);

  /* istanbul ignore next */
  const analyticsCallback = (rfrr: string, linkName: string | null) => {
    analytics?.trackEvent(rfrr, linkName || "RFRR Action Event", analytics.omnitureData, null, true);
  };

  return (
    <UitkSpacing margin={{ blockend: "three" }}>
      <div>
        <CustomerNotificationWithDialog
          inputs={{
            context: getBexApiContext(context),
            lineOfBusiness: identity.lineOfBusiness,
            funnelLocation: identity.funnelLocation,
            location,
          }}
          analyticsCallback={analyticsCallback}
          className={className}
        />
      </div>
    </UitkSpacing>
  );
});
