import { LineOfBusinessDomain, PageLocation } from "__generated__/typedefs";
import { Action } from "../types";
import { PageStore } from "bernie-plugin-mobx";

export const lineOfBusinessMapper: Record<string, LineOfBusinessDomain> = {
  H: "LODGING",
  P: "PACKAGES",
  F: "FLIGHT",
  C: "CAR",
  CR: "CRUISES",
  A: "ACTIVITIES",
  U: "UNCLASSIFIED",
  R: "RAILS",
  GT: "GROUNDTRANSFERS",
};

export const getLineOfBusiness = (lineOfBusiness: string): LineOfBusinessDomain =>
  lineOfBusinessMapper[lineOfBusiness] || "UNCLASSIFIED";

export const funnelLocationMapper: Record<string, PageLocation> = {
  0: "SYSTEM",
  10: "ENTRY",
  20: "SEARCH_RESULTS",
  30: "DETAILS",
  40: "CHECKOUT",
  50: "CHECKOUT_ERROR",
  60: "PAYMENT_ERROR",
  70: "CONFIRMATION",
  80: "POST_BOOKING",
  90: "USER",
  500: "FEATURES",
};

export interface IdentityWithDialog {
  pageId: string;
  lineOfBusiness: string;
  funnelLocation: string;
}

export const getIdentity = (page: PageStore): IdentityWithDialog => {
  const [pageId, lineOfBusiness, funnelLocation] = page.pageId.split(",").map((identifier) => identifier.trim());

  return { pageId, lineOfBusiness, funnelLocation };
};

export const getFunnelLocation = (funnelLocation: string): PageLocation =>
  funnelLocationMapper[funnelLocation] || "ENTRY";

/* istanbul ignore next */
export const sendAnalytics = (
  actions: Action[],
  analyticsCallback: (rfrr: string, linkName: string | null) => void
) => {
  if (actions) {
    actions.forEach((action: Action) => {
      if (action.__typename === "NotificationAnalytics") {
        const description = action.description as string;
        analyticsCallback(action.referrerId, description);
      }
    });
  }
};
